export function downLoad (content, fileName) {
    const blob = new Blob([content], { type: '' })

    if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, fileName)
    }

    const url = window.URL.createObjectURL(blob)

    const a = document.createElement('a')
    document.body.appendChild(a)
    a.href = url
    a.download = decodeURI(fileName) // 命名下载名称
    a.click() // 点击触发下载
    window.URL.revokeObjectURL(url) // 下载完成进行释放
  }
