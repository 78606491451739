import request from '@/utils/request.js'

export async function downloadFileM (fileName) {
    return await request({
        method: 'get',
        url: '/download/'.concat(fileName),
        other: { responseType: 'blob' }
      })
}

export async function showPicM (fileName) {
    return await request({
        method: 'get',
        url: '/show/'.concat(fileName)
    })
}

export async function getAnalyseResultM (times, scope) {
    return await request({
        method: 'post',
        url: '/analysebytimes',
        data: { times: times, scope: scope }
    })
}
