<template>
  <div class="about">
    <h1>
      This is an
      <span style='color:red'>
        Analyses
      </span>
      page
    </h1>
    <div>次数：<input type="text" v-model='analyse.times'></div>
    <div>期数：<input type="text" v-model='analyse.scope'></div>
    <button @click="getAnalyseResult">分析</button>
    <button @click="clearAnalyseResult">清除</button>
    <!-- <a class=linkUrl @click="downloadPic('test.jpg')">下载</a> -->
    <div v-if="picName.show === 1">
      <div>
        红球预测图：
        <div class="linkUrl">
          <a @click="showPic(picName.red)">点击查看</a>
        </div>
        <div class="linkUrl">
          <a @click="downloadPic(picName.red)">下载</a>
        </div>
        <img v-if="picDataRed.show === 1" :src="picDataRed.v" alt="" />
      </div>
      <div>
        蓝球预测图：
        <div class="linkUrl">
          <a @click="showPic(picName.blue)">点击查看</a>
        </div>
        <div class="linkUrl">
          <a text-decoration:none @click="downloadPic(picName.blue)">下载</a>
        </div>
        <img v-if="picDataBlue.show === 1" :src="picDataBlue.v" alt="" />
      </div>
    </div>
    <Toast v-if="toastData.showToast" :message="toastData.toastMessage" />
  </div>
</template>
<script>
import { reactive } from 'vue'
import { downLoad } from '@/utils/public/download.js'
import { downloadFileM, showPicM, getAnalyseResultM } from '@/utils/main/about.js'
import Toast, { useToastEffect } from '@/components/public/Toast.vue'

export default {
  name: 'About',
  components: { Toast },
  setup () {
    const analyse = reactive({ times: '', scope: '' })
    const picName = reactive({ show: 0, red: '', blue: '' })
    const picDataRed = reactive({ v: '', show: 0 })
    const picDataBlue = reactive({ v: '', show: 0 })
    const { toastData, showToast } = useToastEffect()
    const getAnalyseResult = async () => {
      try {
        const result = await getAnalyseResultM(analyse.times, analyse.scope)
        const redName = result.data.red_pic_name
        const blueName = result.data.blue_pic_name
        picName.red = redName
        picName.blue = blueName
        picName.show = 1
      } catch (e) {
        showToast('请求失败')
      }
    }
    const showPic = async (fileName) => {
      try {
        const result = await showPicM(fileName)
        if (fileName.indexOf('blue') !== -1) {
          picDataBlue.v = 'data:image/jpeg;base64,' + result
          picDataBlue.show = 1
        } else {
          picDataRed.v = 'data:image/jpeg;base64,' + result
          picDataRed.show = 1
        }
      } catch (e) {
        showToast('请求图片失败！')
      }
    }
    const downloadPic = async (fileName) => {
      try {
        const content = await downloadFileM(fileName)
        downLoad(content, fileName)
      } catch (e) {
        showToast('请求失败')
      }
    }
    const clearAnalyseResult = () => {
      picName.show = 0
      picDataBlue.show = 0
      picDataRed.show = 0
    }

    return { analyse, picName, picDataRed, picDataBlue, toastData, getAnalyseResult, showPic, downloadPic, clearAnalyseResult }
  }
}
</script>
<style scoped>
.linkUrl {
    color: -webkit-link;
    cursor: pointer;
    text-decoration: underline;
}
</style>
